:root {
  scroll-behavior: smooth;
}
@media screen and (min-width: 800px) {
  :root {
    font-size: 1.1vw;
  }
}
strong {
  margin-right: 4px;
}
